.Button {
  padding: 20px 32px;
  font-family: "AtelierBossa";
  font-size: 22px;
  cursor: pointer;
  text-transform: uppercase;
}

.Button.primary {
  background-color: var(--color-brown);
  color: var(--color-white);

  &:hover {
    background-color: transparent;
    border: 1px solid var(--color-brown);
    color: var(--color-brown);
  }
}

.Button.secondary {
  border: 1px solid var(--color-brown);
  color: var(--color-brown);
}
