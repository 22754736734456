.SearchIcon {
  cursor: pointer;
  background: var(--color-light-creme);

  width: 46px;
  height: 46px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
