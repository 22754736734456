.Header {
  display: flex;
  justify-content: space-between;
  padding: 38px 0;
  width: 100vw;
  z-index: 999999;
  background-color: white;
  align-items: center;
  position: fixed;

  > .Logo {
    margin-left: 80px;
    cursor: pointer;
  }

  .Desktop {
    display: contents;
    z-index: 999999;
  }

  .Mobile {
    display: none;
    background: white;
  }

  @media screen and (max-width: 1000px) {
    > .Logo {
      margin-left: 28px;
      width: 280px;
    }

    .Desktop {
      display: none;
    }

    .Mobile {
      display: block;
      padding-right: 28px;
      z-index: 999999;
      background: white;
    }
  }
}
