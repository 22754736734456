@font-face {
  font-family: "AtelierBossa";
  src: url("./fonts/pp-neue-montreal/ppneuemontreal-book.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AtelierBossa";
  src: url("./fonts/pp-neue-montreal/ppneuemontreal-bold.otf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "AtelierBossa";
  src: url("./fonts/pp-neue-montreal/ppneuemontreal-medium.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "AtelierBossa";
  src: url("./fonts/pp-neue-montreal/ppneuemontreal-italic.otf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "PP Fragment";
  src: url("./fonts/pp-fragment/PPFragment-GlareLight.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --color-creme: #e2d8c8;
  --color-light-creme: rgba(226, 216, 200, 0.3);
  --color-black: #000000;
  --color-white: #ffffff;
  --color-brown: #593229;
  --color-light-gray: #e7e7e7;
  --color-gray: rgba(0, 0, 0, 0.39);
}

.App {
  .banner {
    height: auto;
    width: 100%;
    margin-top: 122px;
  }

  @media screen and (max-width: 1000px) {
    .banner {
      margin-top: 110px;
      height: 90vh;
      object-position: center;
      object-fit: cover;
    }
  }
}
