.MenuMobile {
  width: 100vw;
  background-color: white;
  z-index: 999999;
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 35px 0 130px;
  gap: 100px;

  .Header {
    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: space-between;
    align-self: center;
  }

  .MenuItems {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 60px;
    align-items: center;
  }

  .LanguageSelector {
    color: #867b6f;
    margin-right: 0;
  }
}
