.Portfolio {
  h1 {
    font-family: "PP Fragment";
    text-transform: uppercase;
    font-size: 40px;
    font-weight: 300;
    text-align: center;
    width: 76%;
    margin-left: 10%;
  }

  .CarouselContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
  }
}

@media screen and (max-width: 1293px) {
  .Portfolio {
    h1 {
      width: auto;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 1000px) {
  .Portfolio {
    h1 {
      font-size: 40px;
    }
  }
}

@media screen and (max-width: 600px) {
  .Portfolio {
    h1 {
      font-size: 30px;
    }

    .CarouselContainer {
      width: 100%;
      margin-top: 50px;
      gap: 30px;

      img[alt="media"] {
        height: 70vh;
        max-height: 320px;
        object-fit: cover;
        object-position: center;
      }

      .Button {
        width: 100%;
      }
    }
  }
}
