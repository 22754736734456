.AboutUs {
  position: relative;
  background: white;
  padding-bottom: 1px;
  margin-bottom: 200px;
  scroll-margin-top: 110px;

  .Backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 583px;
    width: 100%;
    background-color: rgb(0, 0, 0);

    .mobileTitle {
      display: flex;
      float: right;
      margin-right: 100px;
      margin-top: 100px;
      color: white;

      flex-direction: column;
      gap: 20px;
      align-items: flex-start;

      & span {
        font-family: "PP Fragment";
        text-transform: uppercase;
        font-size: 28px;
        text-align: right;
        width: 100%;
        position: relative;
        right: 10px;
      }

      & img {
        width: 500px;
      }
    }
  }

  .Content {
    position: relative;
    padding: 0 80px;
    padding-top: 200px;

    .main {
      float: right;
      position: relative;
      top: -150px;

      .text {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-top: 20px;
        text-align: justify;

        p {
          width: 779px;
          font-size: 20px;
          font-family: "AtelierBossa";
        }
      }
    }

    .presenting {
      max-height: 1100px;
      padding-left: 15%;
      display: flex;
      gap: 200px;
      flex-direction: row;

      & > span {
        display: flex;
        flex-direction: column;
        width: 300px;
        gap: 5px;
        font-family: "AtelierBossa";

        .title {
          margin-top: 30px;
          font-weight: 500;
          text-transform: uppercase;
          font-size: 28px;
        }
        .subTitle {
          font-size: 24px;
        }
      }

      .paola {
        position: relative;
        top: 200px;
        left: 10%;
      }

      p {
        font-size: 20px;
      }

      @media screen and (max-width: 1850px) {
        .paola {
          position: relative;
          left: 5%;
        }
      }
    }
  }

  @media screen and (max-width: 1700px) {
    .Backdrop .mobileTitle {
      margin-right: 40px;

      & img {
        width: 400px;
      }
    }

    .Content {
      padding: 0 40px;
      padding-top: 200px;
    }
  }

  @media screen and (max-width: 1570px) {
    .AboutUs {
      margin-bottom: 0;
    }

    .Backdrop {
      height: 550px;

      .mobileTitle {
        width: 300px;
        margin-top: 37px;
        margin-left: 80px;

        & img {
          max-width: 300px;
        }
      }
    }

    .Content {
      .main {
        display: none;
      }

      .presenting {
        max-height: none;
        display: flex;
        justify-content: space-evenly;
        padding-left: 0;
        & > span {
          width: 100%;
          max-width: 400px;
        }
        .paola {
          position: inherit;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .Backdrop {
      height: 400px;
    }

    .Content {
      .presenting {
        & > span {
          width: 100%;
          max-width: 300px;
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .Backdrop {
      height: 400px;

      .mobileTitle {
        margin-right: 40px;
      }
    }

    .Content {
      .presenting {
        & > span {
          margin: auto;
          width: 267px;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .Backdrop {
      height: 400px;
    }

    .Content {
      .presenting {
        flex-direction: column;
        gap: 50px;
        & > span {
          margin: auto;
          width: 100%;
          max-width: 90%;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .AboutUs {
      scroll-margin-top: 140px;

      .Backdrop {
        .mobileTitle {
          float: left;
          & span {
            text-align: left;
            margin-left: 10px;
          }
        }
      }
      .Content {
        .presenting {
          & > span {
            max-width: 400px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1570px) {
  .AboutUs {
    margin-bottom: 0;
  }
}
