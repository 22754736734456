.Footer {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  height: auto;
  justify-content: space-between;
  background-color: var(--color-light-gray);
  align-items: center;
  padding: 80px;
  color: var(--color-black);
  font-family: "AtelierBossa";
  font-size: 18px;
  z-index: 9999;

  .LeftGroup {
    display: flex;
    flex-direction: column;
    gap: 23px;
  }

  .RightGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    gap: 52px;

    .SocialMedia {
      display: flex;
      gap: 30px;

      img {
        cursor: pointer;
      }
    }

    .Links {
      display: flex;
      gap: 40px;
    }
  }
}

@media screen and (max-width: 800px) {
  .Footer {
    padding: 80px 40px 80px;
  }
}

@media screen and (max-width: 650px) {
  .Footer {
    flex-direction: column;
    gap: 50px;
    padding: 60px 40px 60px;

    .LeftGroup {
      margin-bottom: 80px;
      align-items: center;
    }

    .RightGroup {
      align-items: center;

      .Links {
        flex-direction: column;
        gap: 20px;
        align-items: center;
      }
    }
  }
}
