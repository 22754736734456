.IconButton {
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  > {
    margin: auto;
  }
}

.IconButton.primary {
  background: var(--color-creme);
}

.IconButton.secondary {
  background: var(--color-black);
}

@media screen and (max-width: 500px) {
  .IconButton {
    width: 60px;
    height: 60px;
  }
}
