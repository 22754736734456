.MenuItem {
  text-transform: uppercase;
  cursor: pointer;
  font-family: "AtelierBossa";
  font-size: 20px;
  font-weight: 400;
}

.Active {
  border-bottom: 2px solid black;
}
