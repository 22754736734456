.Carousel {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;

  .Media {
    position: relative;
    overflow: hidden;
    width: 95%;

    .Navigation {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;

      .Arrow {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 30px;

        img {
          display: block;
        }
      }
    }
  }

  .Track {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;

    img {
      min-width: 100%;
    }
  }

  .Counter {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 200;
    color: var(--color-black);
    font-family: "PP Fragment";
    align-items: center;
    gap: 15px;
  }
}

@media screen and (max-width: 600px) {
  .Carousel {
    width: 100%;

    .Media {
      width: 100%;

      .Navigation {
        align-content: space-between;
        flex-wrap: wrap;

        .Arrow {
          padding: 15px;
        }
      }
    }

    .Counter {
      display: none;
    }
  }
}
