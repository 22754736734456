.Services {
  position: relative;
  padding-top: 0;
  z-index: 9999;
  background-color: white;

  h1 {
    font-family: "PP Fragment";
    text-transform: uppercase;
    font-weight: 400;
  }

  p {
    font-family: "AtelierBossa";
    color: var(--color-gray);
    font-size: 20px;
  }

  .Highlight,
  .HighlightRight {
    font-family: "PP Fragment";
    text-transform: uppercase;
    font-weight: 200;
    font-size: 35px;
    align-self: end;
    max-width: 350px;
  }

  .HighlightRight {
    text-align: right;
    margin-top: 100px;
  }

  .Consulting {
    position: relative;
    padding-top: 0;
    top: -90px;
    display: flex;
    justify-content: space-between;

    .Content {
      margin-top: 120px;

      p {
        width: 300px;
      }
    }
  }

  .Styling,
  .InteriorDesign {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 50px;

    .Content {
      margin-top: 120px;

      p {
        width: 400px;
      }
    }

    & img {
      width: 52%;
      max-height: 600px;
      object-fit: cover;
    }
  }

  .Styling {
    h1 {
      max-width: 380px;
    }
  }

  .Ready {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    p {
      width: 300px;
    }

    .Content {
      align-self: center;
    }
  }

  .InteriorDesign {
    margin-top: 100px;
  }

  @media screen and (max-width: 1300px) {
    .Highlight,
    .HighlightRight {
      text-align: left;
      align-self: start;
      margin-top: 100px;
      max-width: 100%;
      font-size: 25px;
      margin-top: 50px;
    }

    .Consulting,
    .Styling,
    .InteriorDesign,
    .Ready {
      flex-direction: column;
      gap: 0;

      .Content {
        margin-top: 20px;

        p {
          width: 100%;
        }
      }
    }

    .InteriorDesign,
    .Styling {
      margin-top: 50px;

      .HelperText {
        display: none;
      }

      .Content {
        .Separator {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .Consulting {
      top: -30px;
    }

    .Consulting,
    .Styling,
    .InteriorDesign,
    .Ready {
      & img {
        width: 100%;
        max-height: auto;
      }

      h1 {
        font-size: 32px;
      }
    }
  }
}
