.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000001;

  .Content {
    background-color: white;
    padding: 0 20px 20px;
    border-radius: 10px;
    width: 60%;
    max-width: 90%;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 10px 30px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for the shadow */
  }

  .Header {
    position: sticky;
    top: 0;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 15px;
    padding-top: 20px;

    h2 {
      margin: 0;
      font-size: 1.5rem;
    }

    .CloseButton {
      background: transparent;
      border: none;
      font-size: 1.5rem;
      color: #aaa;
      cursor: pointer;
      transition: color 0.3s ease;
    }

    .CloseButton:hover {
      color: #ff4d4d;
    }
  }

  .Body {
    font-size: 1rem;
    color: #333;
  }
}

@media screen and (max-width: 750px) {
  .Overlay {
    .Content {
      width: 90%;
    }
  }
}
