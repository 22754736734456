.SectionWrapper {
  padding: 50px 80px;
  scroll-margin-top: 122px;
}

@media screen and (max-width: 1000px) {
  .SectionWrapper {
    padding: 50px 40px;
  }
}

@media screen and (max-width: 600px) {
  .SectionWrapper {
    padding: 50px 28px;
    scroll-margin-top: 110px;
  }
}
