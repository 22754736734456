.QuickActions {
  position: fixed;
  bottom: 50px;
  right: 80px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 9999;
}

.CollapsedQuickActions {
  position: fixed;
  bottom: 50px;
  right: 0px;
  width: 20px;
  background-color: var(--color-brown);
}

@media screen and (max-width: 1000px) {
  .QuickActions {
    right: 40px;
  }
}

@media screen and (max-width: 600px) {
  .QuickActions {
    right: 20px;
  }
}
