.Highlight {
  padding: 205px 30px;
  text-align: center;
  font-family: "PP Fragment";
  font-size: 38px;
  line-height: 60px;

  .Content {
    max-width: 900px;
    margin: auto;
    text-transform: uppercase;
  }
}

.Highlight.primary {
  background-color: var(--color-creme);
}

.Highlight.secondary {
  background-color: var(--color-white);
}

@media screen and (max-width: 700px) {
  .Highlight {
    position: relative;
    z-index: 99999;
    font-size: 25px;
    padding: 62px 20px;
    line-height: 45px;
  }
}
